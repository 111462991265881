import React from 'react'
import './pricing.css';
import { BsCheck } from "react-icons/bs";
import { Link } from 'react-router-dom';

const Pricing = () => {
  return (
    <>

      <section class="pricing">
        <div class="price-container">
          <div class="price">
            <div class="price-left">
            <h2>Part Payment</h2>
              <h2>$250</h2>
              <p class="price-p">Limited Access</p>
              <div class="list1">
                <p>You’ll Get;</p>
                <ul>
                <li> <BsCheck className ='check__icon'/> Access to Live Class.</li>
                  <li><BsCheck className ='check__icon'/> Pay $200, Balance equally over 1 months to enjoy same benefits as Full Payment Plan</li>
                </ul>
              </div>
              <Link to='https://flutterwave.com/pay/rx6qaz06mhnj'>
                <button>Pay Now</button>
                </Link>
            </div>

            <div class="price-right">
              <h2>Full Payment</h2>
              <h2>$450</h2>
              <div class="list1">
                <p>You'll Get;</p>
                <ul>
                <li><BsCheck className ='check__icon'/> Access to Live Class.</li>
                <li><BsCheck className ='check__icon'/> Access Community Platform</li>
                <li><BsCheck className ='check__icon'/> Access to  Resources</li>
                <li><BsCheck className ='check__icon'/> Access to Videos</li>
                <li><BsCheck className ='check__icon'/> Access to Practice Exams</li>
                <li><BsCheck className ='check__icon'/>  Write Certification Exam</li>
                <li><BsCheck className ='check__icon'/>  Post-training support and mentoring</li>
                <li><BsCheck className ='check__icon'/> Access to internship and job placement</li>
                  <li><BsCheck className ='check__icon'/> Digital certificate</li>
                </ul>
              </div>
              <Link to='https://flutterwave.com/pay/rx6qaz06mhnj'>
                <button>Pay Now</button>
                </Link>
            </div>
          </div>

        </div>
        <div className='arrow'>
          <Link to='/'>
          <img src={`${require("../../assets/Back-Arrow.png")}`} alt="" class="imag" /></Link></div>
      </section>  

    </>
  )
}

export default Pricing