import React from 'react'
import './courses.css';
import { Link } from 'react-router-dom';


const Courses = () => {
  return (
    <>
      <section class="course">
        <h1> Our Courses</h1>
        <div class="course-container">
          <div class="course-content">
            <h2>SAFe® Product Owner/Product Manager Course</h2>
            <img src={`${require("../../assets/product-owner.png")}`} alt="tellimage" />
            <p>Gain the skills, tools and guidance required to deliver valuable products through effective program increment execution</p>
            <button class="btn"><Link to='/pricing'>Enroll Now</Link></button>
          </div>
          <div class="course-content">
            <h2>Leading SAFe® Course</h2>
            <img src={`${require("../../assets/leading-master.png")}`} alt="tellimage" />
            <p>Discover how companies can build business agility and how to make SAFe work inside your organization to improve quality, productivity, employee engagement and time-to-market.</p>
            <button class="btn" style={{ cursor: 'not-allowed' }}>Coming Soon</button>
          </div>
          <div class="course-content">
            <h2>SAFe® Scrum Master Course</h2>
            <img src={`${require("../../assets/scrum-master.png")}`} alt="tellimage" />
            <p>Discover how to become the change agent by acquiring the skills, tools and guidance to lead team and organization-wide agile transformation with confidence.</p>
            <button class="btn"><Link to='/pricing'>Enroll Now</Link></button>
          </div>
        </div>
        <Link to='/'><img className='return' src={`${require("../../assets/Back-Arrow.png")}`} alt='arrow' /></Link>
      </section>

    </>
  )
};

export default Courses