import React, { useState } from 'react';
import './contact.css';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { db } from '../../firebase/config';
import { collection, addDoc, getDocs, serverTimestamp } from 'firebase/firestore';

const Contact = () => {
  const [submitButton, setSubmitButton] = useState('Send');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const contactUs = async (e) => {
    e.preventDefault();
    setSubmitButton('Sending...');
    setIsSubmitted(true);
    const nameValue = e.target.elements.name.value;
    const emailValue = e.target.elements.email.value;
    const messageValue = e.target.elements.message.value;

    // Check if the "contacts" collection exists
    const contactsCollectionRef = collection(db, 'contacts');
    const contactsSnapshot = await getDocs(contactsCollectionRef);

    if (!contactsSnapshot.empty) {
      // The collection already exists, save the data
      addContact(contactsCollectionRef, nameValue, emailValue, messageValue);
    } else {
      // The collection doesn't exist, create a new collection and save the data
      const newCollectionRef = collection(db, 'contacts');
      addContact(newCollectionRef, nameValue, emailValue, messageValue);
    }
  };

  const addContact = (collectionRef, nameValue, emailValue, messageValue) => {
    addDoc(collectionRef, {
      name: nameValue,
      email: emailValue,
      message: messageValue,
      Time: serverTimestamp(),
    })
      .then(function (docRef) {
        console.log('Document written with ID: ', docRef.id);
        // Clear the form
        setName('');
        setEmail('');
        setMessage('');
        navigate('/success');
        toast.success('Thanks for contacting us!');
        setSubmitButton('Send');
        setIsSubmitted(false);
      })
      .catch(function (error) {
        console.error('Error adding document: ', error);
        navigate('/error');
        toast.error(error.message);
        setIsSubmitted(false);
        setSubmitButton('Send');
      });
  };

  return (
    <>
      <section className="course contact">
        <h1>Connect with us</h1>
        <form onSubmit={contactUs} className="form2">
          <div>
            <input
              type="text"
              name="name"
              placeholder="Full Name"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <textarea
            placeholder="Your message"
            name="message"
            required
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <button type="submit" className={isSubmitted ? 'submitted' : ''} disabled={submitButton === 'Sending...'}>{submitButton}</button>
        </form>

        <div className="contact__details">
          <p>Reach out to us: 081 3520 9415</p>
          <p>Email: hello@tellagile.africa</p>
        </div>

        <div className="imag">
          <Link to="/">
            <img src={`${require('../../assets/Back-Arrow.png')}`} alt="arrow" className="imag" />
          </Link>
        </div>
      </section>
    </>
  );
};

export default Contact;
