import React from 'react'
import './error-success.css';
import { Link } from 'react-router-dom';
const Success = () => {
  return (
    <>

      <div className='error__success'>
        <h1 className='success'>Success!</h1>
        <p>Thank you for contacting us, we have received your message</p>
        <div className='links'>
          <Link to='/'><button>Home</button></Link>
          <Link to='/contact'><button>Back</button></Link>
        </div>
      </div>
    </>
  )
}

export default Success