import {Splide, SplideSlide}from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import './testimonial.css';
import { Testimonies } from '../../data';



const Testimonial = () => {

    const splideOptions = {
        type: 'loop', autoplay: true, pauseOnHover: false, arrows: false, pagination: false, ease: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
        drag   : 'free',
        focus: 'center',
        perPage: 2,
        gap    : '4rem',
        breakpoints: {
        1023: {
      perPage: 2,
      gap    : '2rem',
    },
    640: {
      perPage: 1,
      gap    : '2rem',
    },
        },
      };

  return (
      <>
          <div className='testimonial__container'>
          <Splide options={splideOptions}>
              {Testimonies.map((t) => (
                  <SplideSlide div key={t.id} >
                      <div className='testimony'>
                      <img className='testIcon' src={`${require("../../assets/testIcon.png")}`} alt={t.name} />
                          <div className='testimony__body'>
                              <p>{t.body}</p>
                              <ul>
                                  <li><img src={t.photo} alt="slides" className='testimony__imgs' /></li>
                                  <li>
                                      <span>{t.name}</span>
                                      <span>{t.country}</span>
                                      <span><img src={`${require("../../assets/rating.png")}`} alt='rating' /></span>
                                  </li>
                              </ul>
                              
                          </div>
                      </div>
              </SplideSlide>
       
          ))}
              </Splide>
          </div>   
    </>
  )
}

export default Testimonial