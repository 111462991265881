import React, { useEffect, useRef, useState } from 'react';
import './counter.css';

const Count = ({ start, end }) => {
  const [count, setCount] = useState(start);
  const countRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Change this threshold as per your requirement
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        const interval = setInterval(() => {
          setCount((prevCount) => {
            if (prevCount >= end) {
              clearInterval(interval);
              return end;
            }
            return prevCount + 1;
          });
        }, 300); // Change this interval as per your requirement
      }
    }, options);

    observer.observe(countRef.current);

    return () => observer.disconnect();
  }, [end]);

  return <span ref={countRef}>{count}</span>;
};

const Counter = () => {
  return (
    <ul className='whats__included__counter'>
      <li>
        <span><Count start={0} end={6} />+</span>
        <span>Modules</span>
      </li>
      <li>
        <span><Count start={0} end={3} />+</span>
        <span>Resources</span>
      </li>
      <li>
        <span><Count start={0} end={20} />+</span>
        <span>Videos</span>
      </li>
      <li>
        <span><Count start={0} end={3} /></span>
        <span>Days</span>
      </li>
    </ul>
  );
};

export default Counter;