export const Testimonies = [
    {
        id: 1,
        name: "Funso Okejimi",
        country: "Sweden",
        photo: require("./assets/testimonial/funso.jpg"),
        body: "Peter’s delivery of the training was robust, well thought-out with a strong practical focus on how to effectively serve, guide and coach teams. A first choice course for Scrum Masters."
    },
    {
        id: 2,
        name: "Ayodeji Osunsanya",
        country: "Nigeria",
        photo: require("./assets/testimonial/ayodeji.jpg"),
        body: "Training was fantastic, facilitator was excellent. It honed my skills in product management and helped me advance to a new role as Head of Products. I'm glad I attended the course."
    },
    {
        id: 3,
        name: "Victoria Awotundun",
        country: "Nigeria",
        photo: require("./assets/testimonial/victoria.jpg"),
        body: "The SSM class was highly informative and engaging. The group activities helped reinforce the framework, concepts and techniques taught in the class. It comes highly recommended."
    },
    {
        id: 4,
        name: "Chimezie Ngene",
        country: "Nigeria",
        photo: require("./assets/testimonial/chimezie.jpg"),
        body: "Great Course, insightful and engaging scenarios that have helped me in my career as a Product Manager. I highly recommend this course."
    },
    {
        id: 5,
        name: "Oluwafunmbi Adesakin",
        country: "Nigeria",
        photo: require("./assets/testimonial/oluwafunmbi.jpg"),
        body: "The combination of teaching and group workshops helped me grasp them on the job. An highly recommended course."
    },
    
]