import React from 'react'
import './faq.css';
import { Link } from 'react-router-dom';

const Faq = () => {
  return (
    <>
    
      <section class="course">
        <h1>FAQs</h1>
        <p className='text-center'>Find the answers you're looking for.</p>
        <div class="faq-container">
          <div class="content faq">
            <h2>What is SAFe®?</h2>
            <p>SAFe is the leading system for business agility, helping organizations meet customer demands quickly. Adopt SAFe to manage digital disruption and avoid keeping customers waiting.</p>
          </div>
          <div class="content faq">
            <h2>Is SAFe Recognized and what are the benefits of SAFe®?</h2>
            <p>SAFe is a proven, scalable system trusted by millions of professionals and enterprises worldwide. It improves employee engagement, time-to-market, productivity, and product quality. Expect faster time-to-market, increased productivity, fewer defects, and happier employees. Learn more..</p>
          </div>
          <div class="content faq">
            <h2> How do I get more details about the SSM certification exam?</h2>
            <p>Please read about the SSM exam details <Link to='/'>here</Link> </p>
          </div>
          <div class="content faq">
            <h2> Am I paying extra for the certification exam?</h2>
            <p>No, the certification exam is included in the training cost of N275,000. You do not need to make any extra payment.</p>
          </div>
          <div class="content faq">
            <h2>How do I benefit from the post-training benefits of attending the class?</h2>
            <p>After successful completion of the certification exam, the profile of the student will be shared with clients for internship and permanent hire opportunities. More details will be shared in the class.</p>
          </div>

          <div class="content faq">
            <h2>How does the installment payment works?</h2>
            <p>Pay a down-payment of N150,000 and spread the balance of N150,000 equally over 3 months by paying N50,000 per month. Exam eligibility requires completion of the balance payment. Grace period of 3 months after down-payment.</p>
          </div>
          <div class="content faq">
            <h2> How do I attend the live class?</h2>
            <p>The information for joining the class will be shared with you after payment.</p>
          </div>

          <div class="content faq">
            <h2>How do I reach out for more information?</h2>
            <p>Please send a contact us via <a href="mailto:hello@tellagile">hello@tellagile.africa</a>, whatsapp or call +2348135209415</p>
          </div>

        </div>
        <div class="imag">
          <img src={`${require("../../assets/Back-Arrow.png")}`} alt="" class="imag" /></div>
      </section>
                    
    </>
  )
};

export default Faq