import React, { useEffect } from 'react'
import Slider from '../../components/slider/Slider'
import './home.css';
import { BsCheck } from "react-icons/bs";
import Counter from '../../components/counter/Counter';
import Testimonial from '../../components/testimonial/Testimonial';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';



const Home = () => {

  useEffect(() => {
    AOS.init();
  }, []);


  return (
    <>
      <Slider />
      <div className='colored__line'></div>

      {/* Top section what you will learn starts here */}
      <section className='top__section'>
        <div className='top__section__text' data-aos="zoom-out-up" data-aos-easing="linear">
          <h1>What You'll Learn</h1>
          <p>In our SAFe Scrum Master Course, you will gain a comprehensive understanding of the 
            Scrum Master role beyond the team level. Unlike traditional Scrum training, we focus on the context of the entire enterprise, preparing you to effectively plan and execute the program Increment (PI). This program increment serves as a vital mechanism for alignment throughout all levels of a SAFe organization.
            Throughout the course, you will learn essential aspects of scaling Agile Development, effective facilitation of Scrum practices across the enterprise, and the execution of iteration planning. Expand your Knowledge and skills with us.
          </p>
        </div>

        <div className='top__section__skills'>
          <div className='skills__text' data-aos="slide-right" data-aos-easing="linear" data-aos-duration="800" data-aos-offset="0">
            <ul>
              <li><BsCheck className ='check__icon'/> Learn how to facilitate Scrum Events</li>
              <li><BsCheck className ='check__icon'/> Learn how to  support PI execution</li>
              <li><BsCheck className ='check__icon'/> Learn how to  Coach teams to constantly improve</li>
              <li><BsCheck className ='check__icon'/> Learn how to  implement DevOps for flow & Delivery</li>
              <li><BsCheck className ='check__icon'/> Learn how to Empower and lead an Agile Team</li>
              <li><BsCheck className ='check__icon'/> Learn how to help teams plan and  complete their work.</li>
            </ul>
          </div>

          {/* background elements  */}
          <div className='skils__img' data-aos-easing="linear" data-aos="slide-left" data-aos-duration="800" data-aos-offset="0"><img src={`${require("../../assets/desktop.png")}`} alt='desktop' /></div>
          <div className='element1'><img src={`${require("../../assets/element1.png")}`} alt='element' /></div>
        </div>
        
        
        {/* video illustration  */}
        <div className='whats__included' data-aos-easing="ease-in-out" data-aos="flip-up" data-aos-duration="1200">
          <div className="video__container">
          <iframe
              width="540"
              height="460"
              src="https://www.youtube.com/embed/gDRtJUp1fdE"
              title="YouTube video player"  allow="accelerometer;autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            

          </div>
          <div className='included__text'>
            <h2>What's Included</h2>
            <Counter/>
          </div>
        </div>
        <div className='element2'><img src={`${require("../../assets/element2.png")}`} alt='element' /></div>
      </section>
      {/* Top section what you will learn starts here */}
      

      {/* Testimonial section starts here */}
      <section className='testimonial__section'>
        <h2>Testimonials</h2>
        <Testimonial />
        <Link to='/courses' className='register-btn'><button>Register Now</button></Link>
      </section>
      {/* Testimonial section ends here */}

      {/* Meet the Tutor section  starts here */}
      <section className='tutor__container'>
        <div className='tutor'>
          <ul>
            <li className='tutor__info' data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
              <h3>Meet Your Tutor</h3>
              <img src={`${require("../../assets/tutor.png")}`} alt='tutor' />
            </li>
            <li className='tutor__details' data-aos="flip-right" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
              Peter Daniels  is an experienced Agile and Digital Transformation Executive with 16 years of leadership in Information Technology and Agile Transformation.He holds multiple agile certifications and have led successful lean agile transformations across various industries in multiple countries. His accomplishments include delivering two Digital Banks in Nigeria, coaching high-performing cross-functional agile teams, successfully completing 297 projects, and achieving significant revenue growth. He has also received numerous local and international awards and have trained and mentored professionals both in Nigeria and globally.
            </li>
          </ul>
       </div>

      </section>
      {/* Meet the Tutor section  ends here */}



    </>
  )
}

export default Home