import './footer.css';
import illustration from '../../assets/ILLUSTRATION.png';
import { Link } from 'react-router-dom';
import { BsInstagram } from 'react-icons/bs';
import { AiOutlineMail, AiOutlineLinkedin } from 'react-icons/ai';
import { RiTwitterXFill } from 'react-icons/ri';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { addDoc, collection, getDocs, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase/config';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [submitButton, setSubmitButton] = useState('Subscribe');

  const subscribeUser = async (e) => {
    e.preventDefault();
    setSubmitButton('Subscribing to our Newsletter...');
    const emailValue = e.target.elements.email.value;

    // Check if the "subscribers" collection exists
    const subscribersCollectionRef = collection(db, 'subscribers');
    const subscribersSnapshot = await getDocs(subscribersCollectionRef);

    if (!subscribersSnapshot.empty) {
      // The collection already exists, save the data
      addSubscriber(subscribersCollectionRef, emailValue);
    } else {
      // The collection doesn't exist, create a new collection and save the data
      const newCollectionRef = collection(db, 'subscribers');
      addSubscriber(newCollectionRef, emailValue);
    }
  };

  const addSubscriber = (collectionRef, emailValue) => {
    addDoc(collectionRef, {
      email: emailValue,
      Time: serverTimestamp(),
    })
      .then(function (docRef) {
        // Clear the form
        setEmail('');
        toast.success('Thanks for subscribing!');
        setSubmitButton('Subscribe');
      })
      .catch(function (error) {
        toast.error('An error occurred. Please try again!');
        setSubmitButton('Subscribe');
      });
  };

  return (
    <>
      <footer>
        <div className="newsletter">
          <div className="newsletter__input">
            <h2>Subscribe to get our Newsletter.</h2>

            <form onSubmit={subscribeUser}>
              <input
                type="email"
                placeholder="Email address"
                required
                name='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button type="submit" disabled={submitButton === 'Subscribing to Newsletter'}>{submitButton}</button>
            </form>
          </div>
          <div className="illustration">
            <img src={illustration} alt="" />
          </div>
        </div>

        <div className="footer__text">
          <h3>Get In Touch!</h3>
          <p>Want to hire us for your staff training or speaking at your conference?</p>
          <span>Email us at hello@tellagile.africa</span>

          <ul className="footer__navlinks">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/courses">Courses</Link>
            </li>
            <li>
              <Link to="/pricing">Pricing</Link>
            </li>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
            <li>
              <Link to="/faq">FAQ</Link>
            </li>
          </ul>

          <ul className="footer__socials">
            <li>
              <Link>
                <BsInstagram />
              </Link>
            </li>
            <li>
              <Link>
                <RiTwitterXFill />
              </Link>
            </li>
            <li>
              <Link to='mailto:hello@tellagile.com'>
                <AiOutlineMail />
              </Link>
            </li>
            <li>
              <Link to='https://www.linkedin.com/company/tell-agile/'>
                <AiOutlineLinkedin />
              </Link>
            </li>
          </ul>
          <div className="line"></div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
