import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { About, Contact, Courses, Error, Faq, Home, Pricing, Success } from "./pages";
import { Footer, Header } from "./components";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";



function App() {
  
  return (
    <>
      
      <Router>
      <ToastContainer />

        <Header />
        
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/pricing" element={<Pricing/>} />
          <Route path="/courses" element={<Courses/>} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/error" element={<Error />} />
          <Route path="/success" element={<Success/>} />
        </Routes>
      <Footer/>

      </Router>
      
    </>
  );
}

export default App;
