import React from 'react'
import './about.css'
import { Link } from 'react-router-dom'

const About = () => {
  return (
    <>
    
      <section class="course about">
            <img src={`${require("../../assets/about.png")}`} alt="about" />
        <div class="about-content">
        <div>
        <img src={`${require("../../assets/who.png")}`} alt="who" />
            <h2>who are we</h2>
            <p>We are passionate about empowering professionals across Africa to embrace lean and Agile methodologies. transforming both thier careers and the organizations they belong to. Together, we can shape a future where African professionals are equipped with the skills, mindset, and tools to thrive in an ever-changing work landscape.</p>
        </div>


        <div class="first">
            <img src={`${require("../../assets/what.png")}`} alt="who" />
            <h2>what we do</h2>
            <p>At TellAgile, we are passionate about empowering Scrum Masters to evolve into competent SAFe Scrum Masters. 
        We believe that by combining theoretical knowledge, with practical experiences, continuous learning, and a strong community, Scrum Masters can drive successful Agile transformation within their organizations. Join us on this transformative journey and unlock your potential as a SAFe Scrum Master..</p>
        </div>
        </div>
        <div className='arrow'><Link to='/'><img  src={`${require("../../assets/Back-Arrow.png")}`} alt="" /></Link></div>
        
        </section>
      
    </>
  )
}

export default About