import { Link, NavLink } from 'react-router-dom';
import './header.css';
import { logo } from '..';
import { HiMenu } from "react-icons/hi";
import { RiCloseLine } from "react-icons/ri";
import $ from 'jquery';


const activeLink = ({ isActive }) => (isActive ? `${"active"}` : "");

const Header = () => {

  const menu__close = () => {
    $('.menu__open').toggleClass('block hidden');
    $('.menu__close').addClass('hidden');
    $('.mobile__navLinks').toggleClass('block hidden');
    $('.background__overlay').toggleClass('block hidden');


  };

  const menu__open = () => {
    $('.menu__open').addClass('hidden');
    $('.menu__close').toggleClass('hidden block');
    $('.mobile__navLinks').toggleClass('hidden block');
    $('.background__overlay').toggleClass('block hidden');
  };


  return (
    <>
      <div className='background__overlay laptop:hidden hidden' onClick={menu__close}></div>
      <header>
        {/* logo  */}
        <div className='logo'>
          <Link to='/'>
            <img src={logo} alt='' />
          </Link>
        </div>

        {/* Navlinks  */}
        {/* desktop navlinks */}
        <div className='navLinks'>
          <ul>
            <li><NavLink to='/' className={activeLink}>Home</NavLink></li>
            <li><NavLink to='/courses' className={activeLink}>Courses</NavLink></li>
            <li><NavLink to='pricing' className={activeLink}>Pricing</NavLink></li>
            <li><NavLink to='about' className={activeLink}>About Us</NavLink></li>
            <li><NavLink to='contact' className={activeLink}>Contact</NavLink></li>
          </ul>
        </div>

        {/* Navlinks  */}
        <div className='menu__icon' >
          <HiMenu className='menu__open text-white' onClick={menu__open}/>
          <RiCloseLine className='menu__close hidden' onClick={menu__close}/>
        </div>
        {/* desktop navlinks */}
        <div className='mobile__navLinks hidden'>
          <ul>
            <li><NavLink to='/' className={activeLink} onClick={menu__close}>Home</NavLink></li>
            <li><NavLink to='/courses' className={activeLink} onClick={menu__close}>Courses</NavLink></li>
            <li><NavLink to='pricing' className={activeLink} onClick={menu__close}>Pricing</NavLink></li>
            <li><NavLink to='about' className={activeLink} onClick={menu__close}>About Us</NavLink></li>
            <li><NavLink to='contact' className={activeLink} onClick={menu__close}>Contact</NavLink></li>
          </ul>
          <div className='nav__logo'>
          <img src={`${require("../../assets/logo.png")}`}  alt=''/>
        </div>
        </div>
        
      </header>
    </>
  )
}

export default Header