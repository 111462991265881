import {Splide, SplideSlide}from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import './slider.css';
import { Link } from 'react-router-dom';

const slides = [
    
    {
        id: 1,
      image: `${require("../../assets/slides/1.jpg")}`
    },
    {
        id: 2,
        image: `${require("../../assets/slides/2.jpg")}`
    },
    {
        id: 3,
        image: `${require("../../assets/slides/3.jpg")}`
    },
    {
        id: 4,
        image: `${require("../../assets/slides/4.jpg")}`
    },
  ]

const Slider = () => {

    const splideOptions = {
        type: 'loop', autoplay: true, pauseOnHover: false, arrows: false, pagination: false, ease: 'cubic-bezier(0.25, 0.1, 0.25, 1)', speed: 800
      };


  return (
      <>
          <div className='slider__container'>
          <Splide options={splideOptions}>
              {slides.map((s) => (
                  <SplideSlide div key={s.id} >
                      <div className='slides'>
                          <img src={s.image} alt="slides" className='slider__imgs' />
                      </div>
              </SplideSlide>
       
          ))}
              </Splide>
              <div className='cta__btns'>
                  <Link to='/courses'><button className='btn-get-started'>Get Started</button></Link>
                  <Link to='/about'><button className='btn-learn'>Learn more</button></Link>
              </div>
          </div>   
    </>
  )
}

export default Slider